import {useEffect, useState} from "react";
import {useTranslation} from 'react-i18next';
// '@mui
import {CURRENCIES, LANGS} from '../config'
import {enUS} from 'date-fns/locale'
// ----------------------------------------------------------------------

export default function useLocales() {
    const {i18n, t: translate} = useTranslation();
    const langStorage = localStorage.getItem('i18nextLng');
    const currentLang = LANGS.find((_lang) => _lang.value?.toLowerCase().includes(langStorage.toLowerCase())) || LANGS[0];
    const [currentCurrencySymbol, setCurrencySymbol] = useState('');
    const [decimalSeparator, setDecimalSeparator] = useState('.');
    const [thousandsSeparator, setThousandsSeparator] = useState(',');
    const [dateFnsLang, setDateFnsLang] = useState(enUS);

    useEffect(() => {
        // TODO: re-enable when we are managing this at a parent organization level numeral.locale(currentLang.value);
        setCurrencySymbol(CURRENCIES[currentLang.currencyCode].symbol.default.display)
        setDecimalSeparator(CURRENCIES[currentLang.currencyCode].delimiters.decimal)
        setThousandsSeparator(CURRENCIES[currentLang.currencyCode].delimiters.thousands)

        console.log("USELOCALES CURRENTLANG", currentLang)

        // available date-fns locales can be found here: node_modules/date-fns/locale.d.ts
        setDateFnsLang(currentLang.dateFnsCode)
    }, [currentLang])

    const handleChangeLanguage = (newlang) => {
        i18n.changeLanguage(newlang);
    };

    return {
        thousandsSeparator,
        decimalSeparator,
        onChangeLang: handleChangeLanguage,
        currentCurrencySymbol,
        translate,
        currentLang,
        allLang: LANGS,
        dateFnsLang
    };
}
