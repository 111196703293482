import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
// top level locales
// based on BCP 47 Tags
import enENLocales from './en-en'; // English // TODO: rename actual folders
import deDELocales from './de-de'; // German
import frFRLocales from './fr-fr'; // French
import frCALocales from './fr-ca'; // Canadian French
import esMXLocales from './es-mx'; // Mexican Spanish
import esCULocales from './es-cu'; // Cuban Spanish
import hiINLocales from './hi-in'; // Hindi
import itITLocales from './it-it'; // Italian

// ----------------------------------------------------------------------

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            // en: {translations: enLocales},
            // de: {translations: deLocales},
            // fr: {translations: frLocales},
            // mx: {translations: mxLocales},
            // cu: {translations: cuLocales},
            // hi: {translations: hiLocales},
            // ca: {translations: caLocales},
            // it: {translations: itLocales},
            'fr-CA': {translations: frCALocales},
            'hi-IN': {translations: hiINLocales},
            'es-CU': {translations: esCULocales},
            'fr-FR': {translations: frFRLocales},
            'de-DE': {translations: deDELocales},
            'en-EN': {translations: enENLocales},
            'es-MX': {translations: esMXLocales},
            'en-US': {translations: enENLocales},
            'en-CA': {translations: enENLocales},
            'it-IT': {translations: itITLocales},
        },
        lng: localStorage.getItem('i18nextLng') || navigator.language || 'en',
        fallbackLng: 'en',
        debug: false,
        ns: ['translations'],
        defaultNS: 'translations',
        interpolation: {
            escapeValue: false
        }
    });

console.log({i18n})
export default i18n;
